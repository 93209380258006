import * as React from 'react';
import styled from '@emotion/styled';

type Props = {
  children?: React.ReactNode;
  isHasObi?: boolean;
  isFixed?: boolean;
};

export const Container: React.FC<Props> = ({
  children,
  isHasObi,
  isFixed = true,
}) => {
  return (
    <Wrapper isHasObi={isHasObi} isFixed={isFixed}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isHasObi?: boolean; isFixed?: boolean }>`
  ${({ isHasObi, isFixed }) =>
    isFixed
      ? isHasObi
        ? `
          padding-top: 10rem;
          @media (max-width: 960px) {
            padding-top: 9.5rem;
          }
        `
        : `
          padding-top: 7rem;
        `
      : `
        padding-top: 10rem;
        @media (max-width: 960px) {
          padding-top: 9.5rem;
        }
        @media (max-width: 480px) {
          padding-top: 0;
        }
      `}
`;
