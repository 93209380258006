import { useEffect } from 'react';

const useHubSpotScript = () => {
  useEffect(() => {
    // HubSpotから取得した埋め込みタグのスクリプトを動的にロードする関数
    const loadHubSpotScript = () => {
      const script = document.createElement('script');
      script.src =
        'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    // コンポーネントのマウント時にスクリプトをロード
    loadHubSpotScript();

    // コンポーネントがアンマウントされる時にスクリプトをクリーンアップ
    return () => {
      // 生成されたスクリプトタグを選択
      const hubSpotScript = document.querySelector(
        'script[src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"]'
      );
      // スクリプトタグがあれば削除
      if (hubSpotScript) {
        document.body.removeChild(hubSpotScript);
      }
    };
  }, []);
};

export default useHubSpotScript;
