import { graphql } from 'gatsby';
import { HeadInner } from '../../components/Head';
import { Box } from '@mui/material';
import { Layout } from '../../components/layout';
import { GoogleTagManagerNoScript } from '../../components/GoogleTagManagerNoScript';
import { Container } from '../../components/Container';
import useHubSpotScript from '../../util/hooks/useHubSpotScript';
import styled from '@emotion/styled';
import { FC } from 'react';

export const Head = () => (
  <HeadInner path='/partner' title='オンライン説明のご予約' />
);

type OnlineInstructionsProps = {
  src: string;
};

const OnlineInstructions: FC<OnlineInstructionsProps> = ({ src }) => {
  useHubSpotScript();

  const Wrapper = styled.div`
    background-color: #a6c0db;
    padding: 3rem 0 5rem;
    max-width: 100rem;
    h2 {
      color: var(--color-text-opposite);
      font-weight: 700;
      font-size: 2.8rem;
      letter-spacing: normal;
      text-shadow: 0 0.1rem 0 rgba(0, 0, 0, 0.25);
      br {
        display: none;
      }
    }
    p {
      margin-top: 3rem;
      text-align: center;
      color: var(--color-text-opposite);
    }
    @media (max-width: 960px) {
      h2 {
        font-size: 2.1rem;
        text-align: center;
        br {
          display: block;
        }
      }
      p {
        text-align: left;
        br {
          display: none;
        }
      }
    }
  `;

  return (
    <Wrapper>
      <div className='wrapper'>
        <div className='gutter'>
          <h2 className='u-ta-c'>オンライン説明のご予約</h2>
          <p>
            paildで解決できる課題や活用事例についてオンラインにてご説明します。
            <br />
            下記カレンダーよりご希望の日時をご選択ください。
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: '2rem',
            }}
          >
            <div
              className='meetings-iframe-container' // divにしてこのクラス名にしないとHubSpotのスクリプトが動かない
              data-src={src}
              style={{
                width: '100%',
                height: '85rem',
                border: '0',
                overflow: 'hidden',
              }}
            ></div>
          </Box>
        </div>
      </div>
    </Wrapper>
  );
};

const SchedulePage: FC<{
  data: GatsbyTypes.ScheduleTemplateQueryQuery;
}> = ({ data }) => {
  return (
    <Layout>
      <GoogleTagManagerNoScript />
      <Container isHasObi>
        <OnlineInstructions
          src={
            data.contentfulSchedule?.src ??
            'https://meetings.hubspot.com/rie-mukaigawara/sales-roundrobin?embed=true'
          }
        />
      </Container>
    </Layout>
  );
};

export default SchedulePage;

export const query = graphql`
  query ScheduleTemplateQuery($slug: String) {
    contentfulSchedule(slug: { eq: $slug }) {
      slug
      src
    }
  }
`;
